<template>
  <div class="cardPoll">
    <div class="cardPoll__logo"><iconic name="poll" class="cardPoll__logo--icon"></iconic></div>

    <div @click="getPollByIdAction" class="cardPoll__data">
      <div class="cardPoll__data-title">
        <p>{{ poll.title }}</p>
        <iconic name="edit" />
      </div>
      <div class="cardPoll__data--info">
        <img class="cardPoll__data--img" :src="poll.createdBy.picture" :alt="poll.name" />

        <p>{{ poll.createdBy.name }}</p>
      </div>
    </div>
    <div class="cardPoll__manage-container">
      <div class="cardPoll__results">
        <p>{{ changeDate }}</p>
      </div>
      <div class="cardPoll__actions">
        <div v-if="!validatePoliciesViews()" class="error-active">* No se han definido las políticas de vista.</div>
        <select :disabled="isStatusOptionDisabled" @change="changeStatus" v-model="poll.status" class="cardPoll__options">
          <option :value="status" v-for="(status, index) in availableStatusOptions" :key="index" class="option">
            {{ getStatusLabel(status) }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  export default {
    props: ["poll"],
    data() {
      return {
        statusOptions: ["active", "inactive", "pending", "finished"],
        statusLabels: {
          active: "Activo",
          pending: "Pendiente",
          inactive: "Eliminar",
          finished: "Finalizado",
        },
      };
    },
    computed: {
      changeDate() {
        const fechaISO = this.poll.createdAt;

        const date = new Date(fechaISO);

        const nameMounth = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

        const day = date.getDate();
        const mounth = nameMounth[date.getMonth()];
        const year = date.getFullYear();
        const hour = date.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true });

        const dateFormated = `${day} ${mounth} ${year} - ${hour}`;
        return dateFormated;
      },
      availableStatusOptions() {
        const optionsByStatus = {
          pending: ["pending", "active", "inactive"],
          active: ["active", "finished"],
          finished: ["finished"],
          inactive: ["inactive"],
        };
        return optionsByStatus[this.poll.status];
      },
      isStatusOptionDisabled() {
        return ["finished", "inactive"].includes(this.poll.status);
      },
    },
    methods: {
      ...mapActions("polls", ["getPollById"]),
      validatePoliciesViews() {
        return !!Object.keys(this.poll.policiesView).length;
      },
      getStatusLabel(status) {
        return this.statusLabels?.[status] || status;
      },
      async changeStatus(evt) {
        if (this.validatePoliciesViews()) {
          await this.$store.dispatch("polls/changePollStatus", { id: this.poll._id, status: evt.target.value });
          this.$toast.success("Encuesta actualizada");
        } else {
          this.$toast.success("No se han definido las políticas de vista.");
        }
      },
      async getPollByIdAction() {
        await this.getPollById(this.poll._id);

        return this.$router.push(`/polls/editPoll/${this.poll._id}`).catch(() => {});
      },
    },
  };
</script>
<style lang="scss">
  .cardPoll {
    cursor: pointer;
    padding: 20px;
    margin: 20px 0;
    width: 100%;
    height: 94px;
    border-radius: 15px 20px 20px 20px;
    box-shadow: 0px 0.5px 4px 0px rgba(0, 0, 0, 0.25);
    background: #fff;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    &__logo {
      width: 50px;
      height: 50px;
      border-radius: 6px;
      padding: 10px;
      background: #e9e9e9;
      &--icon {
        font-size: 30px;
        color: #909090;
      }
    }
    &__data {
      width: 70%;
      &-title {
        display: flex;
        align-items: center;
        gap: 10px;
        color: initial;
        .iconic {
          display: none;
        }
        &:hover {
          color: initial;
          .iconic {
            display: block;
          }
        }
      }
      &--info {
        margin: 5px 0;
        display: flex;
        justify-content: flex-start;
        gap: 10px;
      }
      &--img {
        width: 23px;
        height: 23px;
        border: 1px solid $primary_color;
        border-radius: 50%;
      }
    }
    &__results {
      display: flex;
      justify-content: flex-end;
      overflow: hidden;
      text-overflow: ellipsis;
      &-container {
        display: flex;
        flex-direction: column;
      }
    }
    &__options {
      outline: none;
      margin-top: 8px;
      border-radius: 6px;
      padding: 0 5px;
    }
    &__manage-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 5px;
      .error-active {
        color: $primary;
      }
      .cardPoll__actions {
        display: flex;
        align-items: baseline;
      }
    }
  }
</style>
